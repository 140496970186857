import create from 'zustand';
import { NotificationType } from '@/components/base/notification';

type NotificationMessage = {
  type?: NotificationType;
  text: string;
};
type ScoreCardNotificationMessage = {
  text: string;
};
interface NotificationCenterState {
  notificationMessage: NotificationMessage | null;
  setNotificationMessage: (
    notificationMessage: NotificationMessage | null,
  ) => void;
  scoreCardNotificationMessage: ScoreCardNotificationMessage | null;
  setScoreCardNotificationMessage: (
    scoreCardNotificationMessage: ScoreCardNotificationMessage | null,
  ) => void;
  notificationBottomText: string | null;
  setNotificationBottomText: (text: string | null) => void;
}

export const useNotificationCenterStore = create<NotificationCenterState>(
  (set) => {
    return {
      notificationMessage: null,
      setNotificationMessage: (notificationMessage) =>
        set({ notificationMessage }),
      scoreCardNotificationMessage: null,
      setScoreCardNotificationMessage: (scoreCardNotificationMessage) =>
        set({ scoreCardNotificationMessage }),
      notificationBottomText: null,
      setNotificationBottomText: (notificationBottomText) =>
        set({ notificationBottomText }),
    };
  },
);
