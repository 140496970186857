import { StateCreator } from 'zustand';
import { StateStorage } from 'zustand/middleware';

export function localForageStateStore(db: LocalForage): StateStorage {
  return {
    getItem: (name) => db.getItem(name),
    setItem: (name, value) => db.setItem(name, value).then(() => {}),
    removeItem: (name) => db.removeItem(name),
  };
}

/**
 * synchronous State storage that does not persist anything
 */
export function nullStateStore(): StateStorage {
  return {
    getItem: (_name) => null,
    setItem: (_name, _value) => {},
    removeItem: (_name) => {},
  };
}
/**
 * simple identity function to improve type inference
 * of state slices
 * @param fn
 * @returns
 */
export function sliceOf<T>(fn: StateCreator<T>): StateCreator<T> {
  return fn;
}
